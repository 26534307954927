import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import styles from './index.module.scss'
import FAQ from '../../components/FAQ'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {

  render() {

    return (

      <Layout location={this.props.location}>

        <SEO
          title="Podcast To Video Guide 2021"
          description="Step by step guide to convert your podcast into an engaging video podcast to share on social media"
          keywords={[`podcast video conversion`, `Podcast conversion tool`]}
        />

        <article>

          <h1>How to convert a <strong>Podcast to Video</strong></h1>

          <section>
            <p>
              You have a podcast that you want to share as video? Make a podcast video using EchoWave, follow our steps to get started.
            </p>

            <h2>Podcast to Video Online</h2>
            <ol>
              <li id='step1'>
                <strong>Upload the audio file</strong>
                <p>Many social networks cannot post audio files (mp3/wav), instead, you can convert your audio to a video (mp4) by uploading ot EchoWave</p>
              </li>
              <li id='step2'>
                <strong>Add a background branding image</strong>
                <p>Make sure that the video matches the creative brand of your podcast - Many podcasts achieve this by using the cover art as a video background - Creatives like this help boost engagement particularly on social media.</p>
              </li>
              <li id='step3'>
                <strong>Audio-wave animation</strong>
                <p>Animation will help your videos feel dynamic, try adding a waveform animation synced to your audio.</p>
              </li>
              <li id='step4'>
                <strong>Post the Video</strong>
                <p>Render the video and share online, directly to your social networks.</p>
              </li>
            </ol>


            <script type="application/ld+json">{`
                            {
                                "@context": "http://schema.org",
                                "@type": "HowTo",
                                "name": "How to turn your podcast into a video",
                                "description": "Steps to convert your audio podcast into a sharable video.",
                                "image": {
                                "@type": "ImageObject",
                                "url": "https://echowave.io/images/audio-convert.jpg",
                                "height": "491",
                                "width": "900"
                            },
                                "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "currency": "USD",
                                "value": "0"
                            },
                                "tool": [
                                {
                                "@type": "HowToTool",
                                "name": "EchoWave Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "PC or Mobile Phone"
                            }
                                ],
                                "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Upload the audio file",
                                    "url": "https://echowave.io/podcast-to-video/#step1",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Many social networks cannot post audio files (mp3/wav), instead, you can convert your audio to a video (mp4) by uploading ot EchoWave"
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-1.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Add a background branding image",
                                    "url": "https://echowave.io/podcast-to-video/#step2",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Make sure that the video matches the creative brand of your podcast - Many podcasts achieve this by using the cover art as a video background - Creatives like this help boost engagement particularly on social media."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-2.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Animation",
                                    "url": "https://echowave.io/podcast-to-video/#step3",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Animation will help your videos feel dynamic, try adding a waveform animation synced to your audio."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-3.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Post the video",
                                    "url": "https://echowave.io/podcast-to-video/#step4",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Render the video and share online, directly to your social networks."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-4.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                }
                                ],
                                "totalTime": "PT10M"
                            }`}
            </script>


            <section>
              <GetStartedOrChat cta="Podcast to video in three clicks" />
              <br />
            </section>



            <h2>Create a video using FFmpeg</h2>

            <p>There are a few options for converting, you can use an online service such as EchoWave that makes it
              simple
              to convert in a few clicks, or you can use Open Source software like FFmpeg that is more complex but
              free.</p>


            <table className={styles.vsTable}>
              <tr>
                <th>Echowave</th>
                <th>FFmpeg</th>
              </tr>
              <tr>
                <td>
                  Nothing to install
                </td>
                <td>
                  Open source software
                </td>
              </tr>
              <tr>
                <td>
                  Highly Customizable
                </td>
                <td>
                  Free to download and install
                </td>
              </tr>
              <tr>
                <td>
                  Fast to convert
                </td>
                <td></td>
              </tr>
            </table>
          </section>


          <section>
            <h3>Podcast Video Requirements:</h3>
            <ol>
              <li><b>Image to use as a background</b> ideally podcast cover art That matches your brand.</li>
              <li><b>Audio file</b> that you would like to convert</li>
              <li><b>A place to share your podcast</b> - (Depending on where you want to share the optimum video aspect
                ratio will vary)
              </li>
            </ol>
          </section>


          <section>

            <h3>Create podcast video with FFmpeg</h3>
            <p>
              FFmpeg is an excellent choice if you only require a simplistic video, have some technical skill & a desktop computer.
            </p>
            <p>
              FFmpeg requires use fo the terminal - You should only enter commands if you know what they do.
            </p>


            <ol>
              <li>install ffmpeg</li>
              <li>Place the background image and audio into the same directory</li>
              <li>Open a termnal window and run:</li>
            </ol>

            <code>ffmpeg -i background.png -i sound.wav -acodec libvo_aacenc -vcodec libx264 output.flv</code>
            <p>Please change background.png and sound.wav to match your input files.</p>


          </section>

          <section>
            <h2>Audio Conversion FAQ</h2>
            <FAQ items={[
              {
                'question': 'Should I use FFmpeg or EchoWave?',
                'answer': 'EchoWave provides a simple GUI to generate your videos - Meaning you don’t have to be a computer whizz, and even if you are we can scale the render servers for you and setup automation.',
              },
              {
                'question': 'How to customise FFmpeg waveform',
                'answer': 'You can use the command line to customise the waveform for FFmpeg; EchoWave allows you to render bespoke animations configured using our Drag And Drop interface.',
              }
              ]} />

          </section>

        </article>


      </Layout>

    )
  }

}

export default Page
